<template>
  <div class="auth-wrapper auth-v2">
    <b-overlay no-wrap :show="showOverlay" />
    <b-row class="auth-inner m-0">
      <b-link class="brand-logo">
        <b-img
          :src="heroleadsLogo"
          alt="Heroleads Logo"
        />
        <h2 class="brand-text text-primary ml-1 mt-2">
          HeroAIX
        </h2>
      </b-link>
      <b-col lg="8" class="d-none d-lg-flex align-items-center p-5">
        <div class="w-100 d-lg-flex align-items-center justify-content-center px-5">
          <b-img fluid :src="imageUrl" alt="Image" />
        </div>
      </b-col>
      <b-col lg="4" class="d-flex align-items-center auth-bg px-2 p-lg-5">
        <b-col sm="8" md="6" lg="12" class="px-xl-2 mx-auto">
          <b-card-title title-tag="h2" class="font-weight-bold mb-1">
            Welcome to HeroAIX! 👋
          </b-card-title>
          <b-card-text class="mb-2">
            Please log in to your account and start the adventure
          </b-card-text>
          <validation-observer ref="observer">
            <b-form class="auth-login-form mt-2" @submit.prevent>
              <b-form-group
                label="Email"
                label-for="email"
              >
                <validation-provider
                  #default="{ errors }"
                  name="email"
                  rules="required|email"
                >
                  <b-form-input
                    id="email"
                    v-model="email"
                    :state="errors.length > 0 ? false : null"
                    placeholder="johndoe@example.com"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
              <b-form-group
                label="Password"
                label-for="password"
              >
                <validation-provider
                  #default="{ errors }"
                  name="password"
                  rules="required"
                >
                  <b-input-group
                    class="input-group-merge"
                    :class="errors.length > 0 ? 'is-invalid' : null"
                  >
                    <b-form-input
                      id="password"
                      v-model="password"
                      :state="errors.length > 0 ? false : null"
                      class="form-control-merge"
                      :type="passwordFieldType"
                      placeholder="············"
                    />
                    <b-input-group-append is-text>
                      <feather-icon
                        class="cursor-pointer"
                        :icon="passwordToggleIcon"
                        @click="togglePasswordVisibility"
                      />
                    </b-input-group-append>
                  </b-input-group>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
              <b-button
                type="submit"
                variant="primary"
                block
                @click="validationForm"
              >
                Log in
              </b-button>
            </b-form>
          </validation-observer>
          <div class="divider my-2">
            <div class="divider-text">
              or
            </div>
          </div>
          <GoogleLogin
            @success="googleSuccess"
            @fail="googleFail"
          />
        </b-col>
      </b-col>
    </b-row>
  </div>
</template>

<script>
/* eslint-disable global-require */
import CsrfService from '@/services/CsrfService'
import ErrorService from '@/services/ErrorService'
import {
  BOverlay,
  BRow,
  BCol,
  BLink,
  BFormGroup,
  BFormInput,
  BInputGroupAppend,
  BInputGroup,
  BCardText,
  BCardTitle,
  BImg,
  BForm,
  BButton,
} from 'bootstrap-vue'
import GoogleLogin from '@/views/heroai/authentications/components/GoogleLogin.vue'
import SweetAlert from '@/services/SweetAlert'
import Toastification from '@/services/Toastification'
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import {
  required,
  email as emailRule,
} from '@validations'
import { togglePasswordVisibility } from '@core/mixins/ui/forms'
import moment from 'moment'
import axiosInstance from '@/libs/axiosInstance'

export default {
  components: {
    BOverlay,
    BRow,
    BCol,
    BLink,
    BFormGroup,
    BFormInput,
    BInputGroupAppend,
    BInputGroup,
    BCardText,
    BCardTitle,
    BImg,
    BForm,
    BButton,
    GoogleLogin,
    ValidationProvider,
    ValidationObserver,
  },
  mixins: [togglePasswordVisibility],
  data() {
    return {
      // Overlay
      showOverlay: false,

      // Validation
      required,
      emailRule,

      email: '',
      password: '',
      heroleadsLogo: require('@/assets/images/logo/heroleads-logo.png'),
      image: require('@/assets/images/pages/login-v2.svg'),
    }
  },
  computed: {
    imageUrl() {
      if (this.$store.state.appConfig.layout.skin === 'dark') {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.image = require('@/assets/images/pages/login-v2-dark.svg')
        return this.image
      }
      return this.image
    },
    passwordToggleIcon() {
      return this.passwordFieldType === 'password' ? 'EyeIcon' : 'EyeOffIcon'
    },
  },
  async created() {
    await CsrfService.get()
  },
  mounted() {
    this.$store.commit('heroAiAuthentications/doLogout')
    this.$ability.update(this.$store.getters['heroAiAuthentications/getPermissions'])
  },
  methods: {
    async validationForm() {
      try {
        const validationResult = await this.$refs.observer.validate()

        if (validationResult) {
          this.showOverlay = true

          const body = {
            email: this.email,
            password: this.password,
            page: 'HeroAIX',
          }

          const response = await axiosInstance.post('login', body)

          const payload = {
            sanctumToken: response.data.token,
            loginData: response.data.data,
            loginTime: moment(new Date()).format('YYYY-MM-DD HH:mm:ss'),
          }

          await this.prepareLogin(payload)
        }
      } catch (error) {
        this.$swal({ ...SweetAlert.error, html: ErrorService.extractError(error) })
      } finally {
        this.showOverlay = false
      }
    },
    async googleSuccess(data) {
      try {
        this.showOverlay = true

        const body = {
          email: data.email,
          idToken: data.idToken,
        }

        const response = await axiosInstance.post('loginWithGoogle', body)

        const payload = {
          sanctumToken: response.data.token,
          loginData: response.data.data,
          loginTime: moment(new Date()).format('YYYY-MM-DD HH:mm:ss'),
        }

        await this.prepareLogin(payload)
      } catch (error) {
        this.$swal({ ...SweetAlert.error, html: ErrorService.extractError(error) })
      } finally {
        this.showOverlay = false
      }
    },

    async googleFail(error) {
      await this.$swal({ ...SweetAlert.error, html: ErrorService.extractError(error) })
    },

    async prepareLogin(payload) {
      // สำหรับ HeroVision ให้ทำการแสดงเมนูหลัก
      this.$store.commit('appConfig/UPDATE_NAV_MENU_HIDDEN', false)

      // ทำการบันทึกข้อมูลของผู้ใช้งานเก็บไว้ใน Storage
      await this.$store.commit('heroAiAuthentications/doLogin', payload)
      // ทำการแปลง Permission ที่ได้รับมาจาก Backend ให้เป็นรูปแบบที่ Frontend เข้าใจ โดยข้อมูล Permission อยู่ใน loginData
      await this.$store.dispatch('heroAiAuthentications/convertPermissions')
      // ทำการอัพเดต Permission ที่แปลงรูปแบบแล้วเข้าไปใน $ability เพื่อใช้ในการตรวจสอบสิทธิ์ทั้งระบบ
      this.$ability.update(this.$store.getters['heroAiAuthentications/getPermissions'])

      // แสดง Toast ยินดีต้อนรับ
      const fullName = this.$store.getters['heroAiAuthentications/fullName']
      const toastObject = Toastification.getContentSuccess(`Welcome to HeroAIX, ${fullName}`)
      this.$toast(toastObject)

      // ถ้าหากมีข้อมูล Route Name สำหรับการ Redirect ให้ทำเข้าไปยัง URL ดังกล่าวแทน
      // Redirect จะถูกสร้างจาก router/index.js
      if (this.$route.query.redirect) {
        await this.$router.push({ path: this.$route.query.redirect })
      } else {
        await this.$router.push({ name: 'campaigns-list' })
      }
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/pages/page-auth.scss';
</style>
